$(function(){
    getCartItems();
});

function getCartItems() {
    let csrfToken = $('meta[name="csrf-token"]').attr('content');
    $.ajax({
        url: url+'/get-cart-items',
        type: 'POST',
        data: { _token: csrfToken },
        cache: false,
        success: function(data) {
            //console.log("response:",data);
            loadCart(data);
        },
        error: function(jqXHR, textStatus, errorThrown) {
            let message = $.parseJSON(jqXHR.responseText)['message'];
            console.log('Error Occurred', message);
        }
    });
}

function loadCart(data) {
    let cartQuantity = data["totalQuantity"] > 0 ? data["totalQuantity"] : "";
    let totalPrice = "$"+data["totalPrice"];
    $('#CartCount').text(cartQuantity);
    $('#totalPrice').text(totalPrice);

    let htmlString = ""; let warehouseDiv = "";
    let cartItems = data["cartItems"];
    let product = ""; let imagePath = "";
    for(let i=0;i<cartItems.length;i++)
    {
        product = cartItems[i];
        imagePath = "/images/products/medium/"+ product['imagePath'];

        htmlString+='<li class="item">';

        htmlString+='<a class="product-image" href="#">' + '<img src="'+imagePath+'" alt="'+product['styleName']+'" /></a>';

        if(product['warehouseName'])
        {
            warehouseDiv = '<div class="variant-cart">'+product['warehouseName']+'</div>';
        }
        else
        {
            warehouseDiv = "";
        }

        htmlString+='<div class="product-details">' +
                        '<a href="javascript:void(0)" class="remove" rel="'+i+'"><i class="anm anm-times-l" aria-hidden="true"></i></a>' +
                        '<p class="pName">'+product['styleRef']+' - '+product['styleName']+'</p>' +
                         warehouseDiv +
                        '<div class="variant-cart">'+product['colorName']+' / '+product['sizeName']+'</div>' +
                        '<div class="wrapQtyBtn">' +
                            '<div class="qtyField">' +
                                '<span class="label">Qty:</span>' +
                                '<a class="qtyBtn minus btnMinus" href="javascript:void(0);" rel="'+i+'"><i class="fa anm anm-minus-r" aria-hidden="true"></i></a>' +
                                '<input type="text" id="quantity'+i+'" name="quantity" value="'+product['quantity']+'" class="product-form__input qty" readonly>' +
                                '<a class="qtyBtn plus btnPlus" href="javascript:void(0);" rel="'+i+'"><i class="fa anm anm-plus-r" aria-hidden="true"></i></a>' +
                            '</div>' +
                        '</div>' +
                        '<div class="priceRow">' +
                            '<div class="product-price">' +
                                '<span class="money" id="price'+i+'">$'+product['price']+'</span>' +
                            '</div>' +
                        '</div>' +
                    '</div>';
        htmlString+='</li>';
    }

    $('#miniProductsList').html(htmlString);
}

$(document).on('click', '.remove', function ()
{
    let index = $(this).attr('rel');
    let csrfToken = $('meta[name="csrf-token"]').attr('content');
    $.ajax({
        url: url+'/remove-cart-item',
        type: 'POST',
        data: { _token: csrfToken, index },
        cache: false,
        success: function(data) {
            if(data["status"]===1)
            {
                showSuccessMessage("Item deleted successfully!");
                loadCart(data["cartData"]);
                if($("#shoppingCartBody").length){
                    console.log('Element Found');
                    loadShoppingCart(data["cartData"]);
                }
            }
        },
        error: function(jqXHR, textStatus, errorThrown) {
            let message = $.parseJSON(jqXHR.responseText)['message'];
            console.log('Error Occurred', message);
            showErrorMessage("Error Occurred!");
        }
    });
});

$(document).on('click', '.btnMinus', function ()
{
    let index = $(this).attr('rel');
    let quantity = parseInt($('#quantity'+index).val());
    console.log(index,quantity);

    if(quantity>1)
    {
        quantity = quantity - 1;
        updateCartItem(index,quantity);
    }
});

$(document).on('click', '.btnPlus', function ()
{
    let index = $(this).attr('rel');
    let quantity = parseInt($('#quantity'+index).val()) + 1;
    updateCartItem(index,quantity);
});

function updateCartItem(index,quantity) {
    let csrfToken = $('meta[name="csrf-token"]').attr('content');
    $.ajax({
        url: url+'/update-cart-item',
        type: 'POST',
        data: { _token: csrfToken, index, quantity },
        cache: false,
        success: function(data) {
            if(data["status"]===1)
            {
                showSuccessMessage("Item updated successfully!");

                let priceText = "$"+data["price"];
                $('#quantity'+index).val(quantity);
                $('#price'+index).text(priceText);

                let totalPrice = "$"+data["totalPrice"];
                $('#CartCount').text(data["totalQuantity"]);
                $('#totalPrice').text(totalPrice);

                if($("#subTotalPrice").length){
                    $('#subTotalPrice').text(totalPrice);
                    $('#proceedTotalPrice').text(totalPrice);
                }
                if($("#cartQuantity"+index).length) {
                    $('#cartQuantity' + index).val(quantity);
                    $('#cartQuantityXs' + index).val(quantity);
                    $('#productPrice' + index).text(priceText);
                    $('#productPriceXs' + index).text(priceText);

                    let stockQuantity = parseInt($('#stockQty'+index).text());
                    if(quantity > stockQuantity)
                    {
                        $('#cartQuantity'+index).addClass("is-invalid");
                        $('#cartQuantityXs'+index).addClass("is-invalid");
                    }
                }
                window.location.reload();
            }
            else
            {
                showErrorMessage("Error Occurred!");
            }
        },
        error: function(jqXHR) {
            let message = $.parseJSON(jqXHR.responseText)['message'];
            console.log('Error Occurred', message);
            showErrorMessage("Error Occurred!");
        }
    });
}

function showAlert(title,content,type='red',icon='fa fa-warning')
{
    $.alert({
        title: title,
        icon: icon,
        type: type,
        content: content
    });
}

function enableButton(buttonId) {
    $(buttonId).attr("disabled", false);
}

function disableButton(buttonId) {
    $(buttonId).attr("disabled", true);
}

function showSuccessMessage(message) {
    toastr.success(message);
}

function showErrorMessage(message) {
    toastr.error(message);
}

function validateEmail(email) {
    let re = /^([a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)$/i;
    return re.test(email);
}

function isNumeric(num){
    return !isNaN(num)
}

function disableButtonWithLabel(buttonId,btnLabel) {
    $(buttonId).attr("disabled", true).text(btnLabel);
}

function enableButtonWithLabel(buttonId,btnLabel) {
    $(buttonId).attr("disabled", false).text(btnLabel);
}
